<template>
  <div class="wrap">
    <div>
      <div class="card_wrap">
        <div class="card">
          <div class="label">
            <div class="th">{{docDetail.name}}</div>
            <div class="td">电话咨询</div>
            <div class="remark"><span class="money_wrap">¥<span class="money">{{priceInfo.price}}</span>/次</span></div>
          </div>
          <div class="label">
            <div class="th">医院</div>
            <div class="td">{{docDetail.hospital}}</div>
            <div class="remark"></div>
          </div>
          <div class="label">
            <div class="th">职称</div>
            <div class="td">{{docDetail.title}}</div>
            <div class="remark"></div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="label">
          <div class="th grey">对话时长</div>
          <div class="td">有效时长</div>
          <div class="remark">{{priceInfo.minutes}}分钟</div>
        </div>
        <div class="label">
          <div class="th grey">预约时间</div>
          <div class="td">{{priceInfo.inquiry_time}}</div>
          <div class="remark"></div>
        </div>
        <div class="line"></div>
        <div class="label">
          <div class="th bold">共需支付</div>
          <div class="td"></div>
          <div class="remark"><span class="money_wrap">¥<span class="money">{{priceInfo.price}}元</span></span></div>
        </div>
      </div>
    </div>
    <div class="toPay" @click="toPay">去支付</div>
  </div>
</template>
<script>
let self = null;
import { Toast } from 'vant';
import { isWeiXin } from '@/utils/util';
export default {
  data() {
    return {
      docDetail: this.$store.state.docDetail,
      priceInfo: this.$store.state.priceInfo,
      timer: null
    }
  },
  created() {
    self = this;
    // if (this.$route.query.orderId) {
    //   this.getOrderInfo(this.$route.query.orderId);
    // }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 获取第三方创建订单参数
    getCreatedOrderParams(orderId) {
      let docDetail = self.$store.state.docDetail;
      let message = self.$store.state.message;
      let img = self.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let relation = self.$store.state.relation;
      let content = [
        { type: 'text', text: message },
        { type: 'patient_meta', age: relation.age, sex: relation.sex }
      ]
      if (img) {
        content.push({ type: 'image', file: img.join(',') })
      }
      let params = {
        content: JSON.stringify(content),
        partner_order_id: orderId,
        doctor_id: docDetail.id,
        minutes: Number(self.priceInfo.minutes),
        tel_no: self.priceInfo.tel_no,
        price: self.priceInfo.price / (1 + self.$store.state.floatRate),
        inquiry_time: self.priceInfo.inquiry_time
      }
      return params;
    },
    createDevOrder(docType) {
      // let self = this;
      let docDetail = this.$store.state.docDetail;
      let message = this.$store.state.message;
      let img = this.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || ''
      let doctorInfo = {
        doctorId: docDetail.id,
        doctorName: docDetail.name,
        doctorClinic: docDetail.clinic_name,
        doctorImage: docDetail.image,
        doctorConsultPrice: this.priceInfo.price,
        doctorHospitalName: docDetail.hospital_name,
        doctorProfessionalTitle: docDetail.title
      }
      let params = {
        memberId: userData.memberId,
        consultType: 2,
        templateType: 2,
        doctorBelong: docType,
        orderPrice: this.priceInfo.price,
        doctorInfo: doctorInfo,
        interrogationId: this.$store.state.interrogationId,
        questionContent: message,
        questionImage: img && img.join(',')
      }
      this.$http('post', '/server/order/addOrder', params, 1).then(res => {
        // console.log(res);
        if (res.data.code == 200) {
          // console.log(wx.miniProgram.getEnv);
          if (isWeiXin()) {
            wx.miniProgram.getEnv(function (resData) {//获取当前环境
              // console.log(resData);
              if(resData.miniprogram){ // true 在微信小程序中
                // console.log('小程序支付');
                // console.log('getCreatedOrderParams================>', self.getCreatedOrderParams(res.data.data.orderId));
                let url = `/pages/topay?price=${self.priceInfo.price}&orderId=${res.data.data.orderId}&thirdOrder=${JSON.stringify(self.getCreatedOrderParams(res.data.data.orderId))}&pageType=phone&priceInfo=${JSON.stringify(self.priceInfo)}`;
                wx.miniProgram.navigateTo({
                  // url:`/pages/topay?price=${self.priceInfo.price}&orderId=${res.data.data.orderId}`,//跳转回小程序的页面
                  url: url,//跳转回小程序的页面
                  success: function(){
                    // console.log('success');
                    // self.timer = setInterval(() => {
                    //   self.selectByOrderId(res.data.data.orderId);
                    // }, 2000)
                  },
                  fail: function(){
                    // console.log('fail');
                  }
                });
              }else{ // false 在微信公众号里
                // console.log('微信浏览器支付');
                self.pay(res.data.data.orderId);
              }
            });
          } else { // H5支付
            // console.log('H5支付');
            let openid = localStorage.getItem('openid') || ''
            // // console.log(window.navigator.userAgent.match(/MicroMessenger/i))
            // // console.log(window.navigator.userAgent)
            if (openid) {  //公众号支付
              this.pay(res.data.data.orderId);
            } else {
              // this.pay();
              this.wxPay(res.data.data.orderId);
            }
          }
        }
        // let openid = localStorage.getItem('openid') || ''
        // // // console.log(window.navigator.userAgent.match(/MicroMessenger/i))
        // // // console.log(window.navigator.userAgent)
        // if (openid) {  //公众号支付
        //   this.pay(res.data.data.orderId);
        // } else {
        //   // this.pay();
        //   this.wxPay(res.data.data.orderId);
        // }
      })
    },
    thirdOrder(id) {
      let docDetail = this.$store.state.docDetail;
      let message = this.$store.state.message;
      let img = this.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let relation = this.$store.state.relation;
      let content = [
        { type: 'text', text: message },
        { type: 'patient_meta', age: relation.age, sex: relation.sex }
      ]
      if (img) {
        content.push({ type: 'image', file: img.join(',') })
      }
      let params = {
        content: JSON.stringify(content),
        partner_order_id: id,
        doctor_id: docDetail.id,
        minutes: Number(this.priceInfo.minutes),
        tel_no: this.priceInfo.tel_no,
        price: this.priceInfo.price / (1 + this.$store.state.floatRate),
        inquiry_time: this.priceInfo.inquiry_time
      }
      this.$http('post', '/cooperation/server/phone/create_oriented_order/', params).then(res => {
        if (res.data.error == 0) {
          this.bindOrder(id, res.data.service_id);
          this.$store.state.message = '';
          this.$store.state.arrImg = null;

        }
      })
    },
    // 订单号与问题单号绑定接口
    bindOrder(orderId, problemId) {
      let params = {
        orderId: orderId,
        serviceId: problemId,
        buyDuration: this.priceInfo.minutes,
        subscribeTime: this.priceInfo.inquiry_time + ':00'
      }
      this.$http('post', '/server/callOrder/orderAndCallBind', params, 1).then(res => {
        // console.log(res)
        let query = {
          service_id: problemId,
          orderId: orderId
        }
        this.$router.replace({ path: '/tel_wait', query: query })
      })
    },
    // 查询订单状态
    selectByOrderId(orderId) {
      let params = {
        orderId: orderId
      }
      this.$http('get', '/server/order/selectByOrderId', params, 1).then(res => {
        if (res.data.code == 200) {
          // console.log('订单状态', res);
          if (res.data.data.orderStatus == 1) {
            clearInterval(this.timer);
            _this.thirdOrder(orderId);
          } else {
            // clearInterval(this.timer);
            // // console.log('去列表页面===========================');
            this.$store.state.message = '';
            this.$store.state.arrImg = null;
            this.$router.replace({ path: '/consult' })
          }
        }
      })
    },
    // 微信支付
    wxPay(orderId) {
      let data = {
        subject: '充值',
        body: '在线医生',
        price: this.priceInfo.price,//0.01,
        orderNumber: orderId
      }
      this.$http('post', '/server/pay/getH5QrPay', data, 1).then(res => {
        // console.log('支付', res)
        if (res.data.code == 200) {
          // console.log(res.data.data.wapUrl)
          let a = document.createElement("a")
          a.href = res.data.data.wapUrl;
          a.click();
          this.timer = setInterval(() => {
            this.selectByOrderId(orderId)
          }, 2000)
        }
      })
    },
    // 公众号支付
    pay(orderId) {
      let _this = this;
      let openid = localStorage.getItem('openid') || '';
      let data = {
        subject: '充值',
        body: '在线医生',
        price: _this.priceInfo.price, //_this.docDetail.price,//0.01,_this.docDetail.price
        orderNumber: orderId,
        openid: openid
      }
      _this.$http('post', '/server/pay/getJsApiQrPay', data, 1).then(res => {
        // console.log('公众号', res)
        let gzhData = res.data.data;
        // console.log('参数', gzhData.appId)
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": gzhData.appId,     //公众号名称，由商户传入
            "timeStamp": gzhData.timeStamp,         //时间戳，自1970年以来的秒数
            "nonceStr": gzhData.nonceStr, //随机串
            "package": `prepay_id=${gzhData.tradeNo}`,
            "signType": "MD5",         //微信签名方式：
            "paySign": gzhData.paySign //微信签名
          },
          function (res) {
            // console.log(res);
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              _this.thirdOrder(orderId);
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else {
              Toast('支付失败');
              _this.$router.replace({ path: '/consult' })
            }
          });
      })
    },
    // 查询订单信息
    // getOrderInfo(orderId) {
    //   let params = {
    //     orderId: orderId
    //   }
    //   this.$http('get', '/server/order/selectByOrderId', params, 1).then(res => {
    //     if (res.data.code == 200) {
    //       // console.log('查询订单信息', res);
    //       this.devPrice = res.data.data.orderPrice;
    //       this.docDetail.price = res.data.data.orderPrice;
    //       this.$store.state.message = res.data.data.questionContent;
    //       if (res.data.data.questionImage) {
    //         this.$store.state.arrImg = res.data.data.questionImage.split(',');
    //       } else {
    //         this.$store.state.arrImg = null;
    //       }
    //       this.$store.state.relation = { age: res.data.data.age, sex: res.data.data.sex == 1 ? '男' : '女' }


    //     }
    //   })
    // },
    // 支付
    toPay() {
      if (this.$route.query.orderId) {

          // let self = this;
          // console.log(wx.miniProgram.getEnv);

        if (isWeiXin()) {
            wx.miniProgram.getEnv(function (resData) {//获取当前环境
              // console.log(resData);
              if(resData.miniprogram){ // true 在微信小程序中
                // console.log('小程序支付');
                // console.log('getCreatedOrderParams================>', self.getCreatedOrderParams(self.$route.query.orderId));
                let url = `/pages/topay?price=${self.priceInfo.price}&orderId=${self.$route.query.orderId}&thirdOrder=${JSON.stringify(self.getCreatedOrderParams(self.$route.query.orderId))}&pageType=phone&priceInfo=${JSON.stringify(self.priceInfo)}`;
                wx.miniProgram.navigateTo({
                  // url:`/pages/topay?price=${self.priceInfo.price}&orderId=${self.$route.query.orderId}`,//跳转回小程序的页面
                  url: url,//跳转回小程序的页面
                  success: function(){
                      // console.log('success===============');
                      // self.timer = setInterval(() => {
                      //   // console.log('=================去列表页面=======================');
                      //   self.selectByOrderId(self.$route.query.orderId);
                      // }, 2000);
                  },
                  fail: function(){
                    // console.log('fail');
                  }
                });
              }else{ // false 在微信公众号里
                // console.log('微信浏览器支付');
                self.pay(self.$route.query.orderId);
              }
            });
          } else { // H5支付
            // console.log('H5支付');
            let openid = localStorage.getItem('openid') || '';
            if (openid) {  //公众号支付
              this.pay(this.$route.query.orderId);
            } else {
              this.wxPay(this.$route.query.orderId);
            }
        }

        // let openid = localStorage.getItem('openid') || '';
        // if (openid) {  //公众号支付
        //   this.pay(this.$route.query.orderId);
        // } else {
        //   // this.pay();
        //   this.wxPay(this.$route.query.orderId);
        // }
      } else {

        this.createDevOrder(this.$store.state.docType);
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  padding: 0.2rem 0;
  min-height: calc(100% - 0.4rem);
  background: #f8f8f8;
}
.card_wrap {
  // min-height: 600rpx;
}
.card {
  padding: 0.4rem 0.24rem 0.01rem;
  margin-bottom: 0.16rem;
  background: #fff;
  .label {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    margin-bottom: 0.35rem;
    .th {
      width: 1.35rem;
      font-size: 0.26rem;
      color: #333333;
      font-weight: bold;
    }
    .td {
      flex: 1;
      -webkit-flex: 1;
      font-size: 0.28rem;
      color: #666666;
    }
    .remark {
      .money_wrap {
        font-size: 0.24rem;
        color: #ffc025;
        .money {
          font-size: 0.3rem;
          font-weight: bold;
        }
      }
    }
    .grey {
      font-weight: 500;
      font-size: 0.28rem;
      color: #333333;
    }
    .bold {
      font-size: 0.3rem;
    }
  }
  .line {
    margin: 0 0.24rem 0.28rem;
    height: 2px;
    background: #f2f2f2;
  }
}
.toPay {
  margin: 0 0.4rem;
  height: 0.72rem;
  margin-top: 1rem;
  line-height: 0.72rem;
  font-size: 0.32rem;
  text-align: center;
  border-radius: 0.08rem;
  background: #42cec8;
  color: #fff;
}
</style>
